import React, {useEffect, useRef, useState} from 'react'
import QRCode from "qrcode.react"
import CryptoJS from 'crypto-js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './App.css'
import QRCodeDiff from "./QRCodeDiff";
import * as queryString from "query-string";

const App = () => {
    //-------------------------------------------------------------------------------------
    //Text in languages
    //-------------------------------------------------------------------------------------
    const text = {
        en:
            {
                Info: 'You can change the Secret Code\nand observe compliance changes.',
                Header: 'Verifying Compliance of\nSecretCode to OpenCode',
                Header2: 'Verifying Compliance of\nSecretCode to GivenCipher',
                OpenCode: 'OpenCode',
                OpenCode2: 'GivenCipher',
                SecretCode: 'SecretCode',
                //2025-03-20 Hash: 'Hash',
                Hash: 'Cipher',
                Difference: 'Difference',
                Yours: 'Yours',
                Key: 'Key',
                Data: 'Data'
            },
        ru: {
            Info: 'Вы можете изменять Секретный Код\nи наблюдать изменения соответствия.',
            Header: 'Проверка соответствия\nСекретного и Открытого Кодов',   
            Header2: 'Проверка соответствия\nCекретного кода и Вашего шифра', 
            OpenCode: 'ОткрытыйКод',
            OpenCode2: 'ВашШифр',
            SecretCode: 'СекретКод',
            Hash: 'Шифр',
            Difference: 'Разность',
            Yours: 'Ваш',
            Key: 'Ключ',
            Data: 'Данные'
        }
    }

    //Определяем язык (из адресной строки ...index.xx.html... выделяем xx)
    const location = window.location.href
    let lang = (/index\.(\w\w)\.html/).exec(location)
    if (lang) lang = lang[1]
    if (!(lang in text)) lang = 'en'
    const {
        Header, Header2,
        SecretCode,
        Hash,
        OpenCode, OpenCode2,
        Difference,
        Info,
        Yours,
    } = {...text[lang]}

    //-------------------------------------------------------------------------------------
    //images src
    //-------------------------------------------------------------------------------------
    // const pictLogo = './img/logo.svg'
    const pictRefresh = './img/repeat.png'

    //-------------------------------------------------------------------------------------
    //useState variables
    //Some variables needs useRef reference for use in setInterval handler
    //-------------------------------------------------------------------------------------

    //Initialize by testing data
    const testSecretCode = 'TestKey0123456789_ACDFACACDFAC_CDFACACDFACA'
    const [mode, setMode] = useState('2')
    // const [mode, setMode] = useState('3')
    const [secretCode, setSecretCode] = useState(testSecretCode)
    const [publicCode, setPublicCode] = useState(CryptoJS.MD5(testSecretCode).toString())
    const secretRef = useRef(secretCode)
    secretRef.current = secretCode

    //Animation phase
    const [phase, setPhase] = useState(0)

    const phaseRef = useRef(phase)
    phaseRef.current = phase

    //setInterval handler
    const [timerId, setTimerId] = useState(undefined)
    const timerRef = useRef(timerId)
    timerRef.current = timerId

    //secretCode, when edited
    const [secretEditable, setSecretEditable] = useState(undefined)

    //-------------------------------------------------------------------------------------
    //isAnimationEnd
    //-------------------------------------------------------------------------------------
    const isAnimationEnd = secretCode.length && (phase === secretCode.length)

    //-------------------------------------------------------------------------------------
    //Take string fragment  for animation
    //-------------------------------------------------------------------------------------
    const secretCodePhase = secretEditable || secretCode.slice(0, Math.min(secretCode.length, phase))
    const hashCodePhase = CryptoJS.MD5(secretCodePhase).toString()

    //-------------------------------------------------------------------------------------
    // useEffect.
    //On start:
    //  - extract secretCode and publicCode from location
    //  - start timer for animation
    //On finish:
    //  - stop timer if it's running
    //-------------------------------------------------------------------------------------
    useEffect(() => {
        //Extract secretCode and publicCode
        const str = queryString.parse(getLocation(window.location.href).search)
        const pub = str.public_code || str.open_code
        const sec = str.secret_code
        if (pub && sec) {
            setPublicCode(pub)
            setSecretCode(sec)
            setMode(str.mode)
        }

        //Start interval timer for animation
        setTimerId(setInterval(onTimeout, 100))

        //Stop timer
        return () => {
            if (timerId) {
                clearInterval(timerId)
                setTimerId(undefined)
            }
        }
        // eslint-disable-next-line
    }, [])     //only one time: onMount and onUnmount

    //-------------------------------------------------------------------------------------
    // onTimeout - set next phase for animation
    //-------------------------------------------------------------------------------------
    const onTimeout = () => {
        if (secretRef.current.length && phaseRef.current >= secretRef.current.length) {
            if (timerRef.current !== undefined) {
                clearInterval(timerRef.current)
                setTimerId(undefined)
            }
        } else {
            if (secretRef.current.charAt(phaseRef.current) === '_') {
                clearInterval(timerRef.current)
                setTimerId(setInterval(onTimeout, 250))
            }
            setPhase(phaseRef.current + 1)
        }
    }

    //-------------------------------------------------------------------------------------
    // onTLink - back to hash site
    //-------------------------------------------------------------------------------------
    function getLocation(href) {
        const match = href.match(/^(https?:)\/\/(([^:/?#]*)(?::([0-9]+))?)([/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/);
        return match && {
            href: href,
            protocol: match[1],
            host: match[2],
            hostname: match[3],
            port: match[4],
            pathname: match[5],
            search: match[6],
            hash: match[7]
        }
    }

    const onTLink = () => {
        const location = getLocation(window.location.href)
        if (location !== null) {
            const uri2 = location.protocol + "//hash.cryptochainlab.com" + location.pathname + location.search
            window.open(uri2, '_self')
        }
    }

    //-------------------------------------------------------------------------------------
    // onRefresh - start animation
    //-------------------------------------------------------------------------------------
    const onRefresh = () => {
        setPhase(0)

        if (timerId) clearInterval(timerId)
        setTimerId(setInterval(onTimeout, 100))

        setSecretEditable(undefined)
    }

    //-------------------------------------------------------------------------------------
    // Other code
    //-------------------------------------------------------------------------------------
    const getDifference = (first, second) => {
        let result = ''
        for (let i = 0; i < Math.max(first.length, second.length); i++) {
            result += first.charAt(i) === second.charAt(i) ? '0' : '1'
        }
        return result
    }

    const getDifferColored = (first, second) => {
        let isEqualFragment = true
        let fragment = ''
        let key = 0
        let result = []

        const pushFragment = () => {
            if (fragment.length) {
                if (isEqualFragment) result.push(<span key={key++} className={'t-green'}>{fragment}</span>)
                else result.push(<span key={key++} className={'t-red'}>{fragment}</span>)
            }
        }
        for (let i = 0; i < first.length; i++) {
            if ((first[i] === second[i] && isEqualFragment) ||
                (first[i] !== second[i] && !isEqualFragment)) fragment += first[i]
            else {
                pushFragment()
                isEqualFragment = !isEqualFragment
                fragment = first[i]
            }
        }
        pushFragment()

        return result
    }

    const differPublic = getDifference(hashCodePhase, publicCode)
    const differPublicColored = getDifferColored(differPublic, '00000000000000000000000000000000')

    const getOpenCode = () => mode === '2' ? OpenCode2 : OpenCode
    const getHeader = () => mode === '2' ? Header2 : Header

    const makeSecretCode = () => <>
        <div className='d-flex position-relative'>
            {SecretCode}:
            {/* 2025-03-20 */}
            <span className={`font-weight-bold ml-2${secretEditable && secretEditable !== secretCode ? '' : ' invisible'}`} onClick={() => setSecretEditable(undefined)}>{'<--'}</span>
            <button className='clTLink' onClick={onTLink}>[ T ]</button>
            <img src={pictRefresh} className='clRefresh' alt='' onClick={onRefresh}/>
        </div>
        <textarea className='form-control bg-success clSecret'
                  id='secret_code'
                  value={secretCodePhase}
                  disabled={!isAnimationEnd}
                  onChange={(e) => {
                      setSecretEditable(e.target.value)
                  }}
        />

        <div className='clRowQR'>
            <QRCode className='clQR' renderAs='svg' level='Q' value={secretCodePhase}/>
            <button className='clQRsign clQRminus'>-</button>

            <div className='clQRlogo'>{Yours}:</div>
            <QRCode className='clQR' renderAs='svg' level='Q' value={secretCode}/>
            {/*TODO <img className='clQRlogo' src={pictLogo} alt=""/> */}
            <button className='clQRsign clQRequel'>=</button>

            <QRCodeDiff className='clQR' level='Q' value1={secretCodePhase} value2={secretCode} bgColor='red' fgColor={'green'}/>

        </div>
    </>
    //-------------------------------------------------------------------------------------
    // Return now
    //-------------------------------------------------------------------------------------
    return (
        <div id='main'>
            {/* -------------------------------------------------------------------------------------
            //                      Header
            //------------------------------------------------------------------------------------- */}
            <div className='position-relative'>
                <button className='clTReturn' onClick={window.close}>{'<-'}</button>
                <div className='header'>{getHeader()}</div>
            </div>

            {/* -------------------------------------------------------------------------------------
            //                      SecretCode:
            //------------------------------------------------------------------------------------- */}
            {makeSecretCode()}

            {/* -------------------------------------------------------------------------------------
            //                      Hash(SecretCode):
            //------------------------------------------------------------------------------------- */}
            <div>{Hash}({SecretCode}):</div>
            <input
                className='field-check form-control'
                value={hashCodePhase}
                readOnly
            />

            <div className='clRowQR clBorder'>
                <QRCode className='clQR clQRshift' renderAs='svg' level='Q' value={hashCodePhase}/>
            </div>

            {/* -------------------------------------------------------------------------------------
            //                      OpenCode:
            //------------------------------------------------------------------------------------- */}
            <div>{getOpenCode()}:</div>
            <input className={'field-check form-control bg-transparent'}
                   value={publicCode}
                   readOnly
            />

            <div className='clRowQR clBorder'>
                <div className='clQRlogo'>{Yours}:</div>
                <QRCode className='clQR clQRshift' renderAs='svg' level='Q' value={publicCode}/>
                {/*TODO <img className='clQRlogo' src={pictLogo} alt=''/> */}
            </div>

            {/* -------------------------------------------------------------------------------------
            //                      Hash(SecretCode) - OpenCode:
            //------------------------------------------------------------------------------------- */}
            <div>{Hash}({SecretCode}) - {getOpenCode()}:</div>
            <div className={`field-check form-control ${publicCode === hashCodePhase ? 'alert-success' : 'alert-warning'}`}>
                {differPublicColored}
            </div>

            <div className='clRowQR'>
                <QRCode className='clQR' renderAs='svg' level='Q' value={hashCodePhase}/>
                <button className='clQRsign clQRminus'>-</button>

                <div className='clQRlogo'>{Yours}:</div>
                <QRCode className='clQR' renderAs='svg' level='Q' value={publicCode}/>
                {/*TODO <img className="clQRlogo" src={pictLogo} alt=''/> */}
                <button className='clQRsign clQRequel'>=</button>

                <QRCodeDiff className='clQR' level='Q' value1={hashCodePhase} value2={publicCode} bgColor='red' fgColor={'green'}/>
            </div>

            {/* -------------------------------------------------------------------------------------
            //  Labels:            Hash            OpenCode            Difference
            //                  (SecretCode)
            //------------------------------------------------------------------------------------- */}
            <div>
                <label className='clLabelHash'>{Hash}({SecretCode})</label>
                <label className='clLabelOpen'>{getOpenCode()}</label>
                <label className='clLabelDiff'>{Difference}</label>
            </div>

            {/* -----------------------------------------------------------------------------------
            //                      Info Line
            //------------------------------------------------------------------------------------- */}

            <div className={`clInfo`} role="alert">
                {Info}
            </div>
            {/* -------------------------------------------------------------------------------------
            //End main container
            //------------------------------------------------------------------------------------- */}
        </div>
    )
}

export default App
